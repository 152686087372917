<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <img alt="ZOZ" src="../assets/komedt-logo.png" width="300">
            <div class="subheader" >
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
          <p>
            Im Jahr 2019 vereinbarten die DKG und die DGHO die gemeinsame Zertifizierung der Onkologischen Zentren unter der Führung der DKG. Für die Onkologischen Zentren der DGHO, die die komplexen Kriterien der Zertifizierung eines Onkologischen Zentrums der DKG nicht erfüllen können, wurde vereinbart, dass über die DGHO die Zertifizierung als Kompetenzzentrum für Medikamentöse Tumortherapie angeboten werden kann.
          </p><p>
            Dies soll zur Qualitätssicherung in der flächendeckenden onkologischen Versorgung führen. Diese Zertifizierung kann von kleineren Kliniken und auch von Praxen angestrebt werden. Die Zertifizierung ist nicht auf einzelne Krankheitsentitäten fokussiert, sondern betrachtet vor allem die Diagnostik und die Therapie der medizinischen Onkologie incl. Infrastruktur und Prozesse.
          </p><p>
            Der fachliche Inhalt ist deckungsgleich mit dem Abschnitt Medikamentöse Onkologische Therapie aus dem Anforderungskatalog der Onkologischen Zentren nach DKG und wurde in den Bereichen Tumorkonferenz, Apotheke, Anforderungen an die Pflegefachkräfte, Studien, Qualitätszirkel, Palliativmedizin und Räumlichkeiten durch die DGHO ergänzt, bzw. an das Verfahren angepasst.
          </p><p>
            Die Fachexperten für die Zertifizierung der Kompetenzzentren müssen auch die Ausbildung bei OncoZert durchlaufen, damit sichergestellt werden kann, dass die Anforderungen der DKG auch bei der Zertifizierung der Kompetenzzentren für Medikamentöse Tumortherapie erfüllt werden.
          </p><p>
            <a target="infos" 
              href="https://www.komedt.de/verfahrensanweisungen-und-formulare/checkliste-v-1-0-_komedt_neu.pdf">
              Anforderungskatalog Zentrum für Medikamentöse Tumortherapie
            </a>
          </p>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import { router } from '../router'


export default {
  name: 'Hilfe',

  components: {
  },

  data () {
    return {
      items: [],
      loggedIn: false
    }
  },


  created: function () {
    // --- sorgt dafür, dass die Seite nur für angemeldete benutzer sichtbar ist.
    if (Vue.cookie.get('__ac')) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
      if (router.history.current.name !== 'Login') {
        router.push({'name': 'Login'})
      }
    }
  },


}
</script>
