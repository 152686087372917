<!-- 
===============================================================
Download-Bereich bei den Zentren
===============================================================
-->
<template>
  <v-expansion-panels 
    v-if="downloads.length > 0"
    class="downloads my-4" 
    tile 
    accordion>
    <v-expansion-panel class="pa-0">
      <v-expansion-panel-header>
        <h3>{{ title }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div><pre v-if="debugMode">
TITEL:        {{ title }}
PERMISSONS:   {{ permissions }}
ROLES:        {{ roles }}
CENTER:       {{ center.title }}
DCOUMENTS:    {{ downloads }}
        </pre></div>
        <div 
          class="documents" 
          v-for="dl in downloads" 
          :key="dl.id">          
          <a 
            class="filename pr-5" 
            href="#download"
            :title="dl.title + ' (' + Math.round((dl.size/1024)*10)/10 + ' Kb)'"
            @click.prevent="downloadFile(dl)">
            <v-icon
              size="60"
              :color="icon_colors[dl.content_type] + ' darken-2' ">
              {{ mimetypes[dl.content_type] || 'mdi-file-document'}}
            </v-icon>               
              {{ dl.filename }}  ({{ Math.round((dl.size/1024)*10)/10 }} Kb)
          </a>

        </div><!-- :href="dl.download_path" -->

        <div v-if="
          permissions.includes('zoz2.content: Edit Requirement Evaluation')
        ">
          <span class="sendemail-extra mt-8">
            <v-icon dark color="blue">mdi-email</v-icon>
            <a :href="'mailto:' + komedtEmailAddress + 
              '?Subject=AZ: ' + center.aktenzeichen + ' | ' + center.title + 
              '&body=' + 'Ergänzende Dokumente/Reports zum Projekt ' +
              center.aktenzeichen + ': ' + 
              center.title + ' \n(Dokument(e) bitte als Anlage hinzufügen)'
              ">
              Ergänzende Dokumente/Reports per E-Mail an das KoMedT-Backoffice senden
            </a>.
          </span>
        </div>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  
</template>



<script>

import { 
  debugMode,
  PloneRestApi,
  mimetypes,
  icon_colors,
  komedtEmailAddress
} from '@/config'


export default {

  props: [
    'title',
    'center',
    'downloads',
    'location',
    'permissions',
    'roles'
  ],

  data () {
    return {
      mimetypes: mimetypes,
      icon_colors: icon_colors,
      komedtEmailAddress: komedtEmailAddress,
      debugMode: JSON.parse(debugMode), // true/false-String
    }
  },

  methods: {
    downloadFile(file) {

      /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
      console.info("************ Download: " + file)
      console.log(file)

      PloneRestApi.defaults.headers['Authorization'] = 'Bearer ' + this.$cookie.get('__ac')

      /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
      PloneRestApi(file.download_path, {responseType: 'blob'})
        .then(response => {
          if (response.data) {
            const blob = new Blob([response.data], { type: file.content_type })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = file.title
            link.click()
            URL.revokeObjectURL(link.href)
          }
        }) /* then */
        .catch(e => {
          alert(e)
        }) /* catch */
    }
  }

}


</script>



<style lang="scss" scope>

.documents {
    white-space: nowrap;
    display: block;   
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    a {
      text-decoration: none;
    }

}
.filename {
    font-size: 80%;
}

</style>