<template>
    <div>
      <span>© 2021 Deutsche Gesellschaft für Hämatologie und Medizinische Onkologie e. V.</span>
      <span class="small">Software-Version:  {{ softwareVersion }}</span>
      <span class="small">Projektstatus: {{ projectStatus }}</span>
      <pre v-if="debugMode" class="small">Vue-Version: {{ vueVersion }}</pre>
    </div>
</template>

<script>
  import Vue from 'vue'
  import {
    softwareVersion, 
    projectStatus,
    debugMode
  } from '@/config'

  export default {
    name: 'Footer',


    data () {
      return {
        vueVersion: Vue.version,
        softwareVersion: softwareVersion,
        projectStatus: projectStatus,
        debugMode: JSON.parse(debugMode) // true/false-String,
      }
    },

    mounted() {
      console.log('SW Status:' + softwareVersion)
      console.log('PR Status: ' + projectStatus)
    }
  }
</script>

<style scope>
.small {
  font-size: 86%;
  color: #999;
  padding-left: 100px;
}
</style>
